import { isNumber } from 'lodash'
import Link from 'next/link'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { DRIVER_EVIDENCE_DASHBOARD_VERSION } from '~shared/config/driver-evidence'

import { PieChartKey } from '@/dashboard/components/Dashboard/PieChartKey'
import PieChartTile from '@/dashboard/components/Dashboard/PieChartTile'
import StatTile from '@/dashboard/components/Dashboard/StatTile'
import type { StatType } from '@/dashboard/utils/stats'

export interface EvidenceStats {
  expires: Record<'requested' | 'total' | 'percentage', StatType>
  expired: Record<'requested' | 'total' | 'percentage', StatType>
  requested: StatType
  reuploaded: StatType
  expiresWithExpired: StatType
}

interface Props {
  stats: EvidenceStats
}

export const EvidenceTiles = React.memo<Props>(({ stats }) => {
  const { t } = useTranslation()

  const { expires, expired, ...stat } = stats

  return (
    <div className='flex flex-col items-start gap-4 xl:flex-row'>
      <div className='inline-grid w-full grid-cols-1 gap-4 sm:grid-cols-2'>
        {DRIVER_EVIDENCE_DASHBOARD_VERSION === 'v1' && (
          <Link href='/reports/driver-evidence'>
            <a
              className='col-span-full'
              data-testid='evidence-tiles-stat-tile-expires-with-expired'
            >
              <StatTile
                title={t('evidence_tiles.stat_tile.expires-with-expired.title')}
                description={t(
                  'evidence_tiles.stat_tile.expires-with-expired.description',
                )}
                colour='orange'
                value={stats.expiresWithExpired}
              />
            </a>
          </Link>
        )}
        {DRIVER_EVIDENCE_DASHBOARD_VERSION === 'v2' && (
          <>
            <Link href='/reports/driver-evidence?range=due-to-expire'>
              <a data-testid='evidence-tiles-stat-tile-expires'>
                <StatTile
                  title={t('evidence_tiles.stat_tile.expires.title')}
                  description={t(
                    'evidence_tiles.stat_tile.expires.description',
                  )}
                  colour='orange'
                  value={expires.total}
                />
              </a>
            </Link>
            <Link href='/reports/driver-evidence?range=expired'>
              <a data-testid='evidence-tiles-stat-tile-expired'>
                <StatTile
                  title={t('evidence_tiles.stat_tile.expired.title')}
                  description={t(
                    'evidence_tiles.stat_tile.expired.description',
                  )}
                  colour='red'
                  value={expired.total}
                />
              </a>
            </Link>
          </>
        )}
        <Link href='/reports/driver-evidence?status=REQUESTED'>
          <a data-testid='evidence-tiles-stat-tile-requested'>
            <StatTile
              className='col-span-full 2xl:col-span-1'
              title={t('evidence_tiles.stat_tile.requested.title')}
              description={t('evidence_tiles.stat_tile.requested.description')}
              colour='purple'
              value={stat.requested}
            />
          </a>
        </Link>
        <Link href='/reports/driver-evidence?status=UPLOADED'>
          <a data-testid='evidence-tiles-stat-tile-reuploaded'>
            <StatTile
              className='col-span-full 2xl:col-span-1'
              title={t('evidence_tiles.stat_tile.reuploaded.title')}
              description={t('evidence_tiles.stat_tile.reuploaded.description')}
              colour='green'
              value={stat.reuploaded}
            />
          </a>
        </Link>
      </div>
      <div className='inline-grid w-full grid-cols-1 gap-4 sm:grid-cols-2'>
        <PieChartTile
          testId='evidence-tiles-pie-chart-tile-expires'
          className='w-full'
          title={t('evidence_tiles.pie_chart_tile.expires.title')}
          percentage={expires.percentage}
          value={isNumber(expires.requested) ? expires.requested : '-'}
          pieTitle={t('evidence_tiles.pie_chart_tile.expires.pie-title')}
          description={
            <PieChartKey
              keys={[
                {
                  count: `${
                    isNumber(expires.requested) ? expires.requested : '-'
                  }`,
                  key: t(
                    'evidence_tiles.pie_chart_tile.expires.description.requested',
                  ),
                  testId: 'requested',
                },
                {
                  count: `${
                    isNumber(expires.total) && isNumber(expires.requested)
                      ? expires.total - expires.requested
                      : '-'
                  }`,
                  key: t(
                    'evidence_tiles.pie_chart_tile.expires.description.not-requested',
                  ),
                  testId: 'not-requested',
                },
              ]}
            />
          }
        />
        <PieChartTile
          testId='evidence-tiles-pie-chart-tile-expired'
          className='w-full'
          title={t('evidence_tiles.pie_chart_tile.expired.title')}
          percentage={expired.percentage}
          value={isNumber(expired.requested) ? expired.requested : '-'}
          pieTitle={t('evidence_tiles.pie_chart_tile.expired.pie-title')}
          description={
            <PieChartKey
              keys={[
                {
                  count: `${
                    isNumber(expired.requested) ? expired.requested : '-'
                  }`,
                  key: t(
                    'evidence_tiles.pie_chart_tile.expired.description.requested',
                  ),
                  testId: 'requested',
                },
                {
                  count: `${
                    isNumber(expired.total) && isNumber(expired.requested)
                      ? expired.total - expired.requested
                      : '-'
                  }`,
                  key: t(
                    'evidence_tiles.pie_chart_tile.expired.description.not-requested',
                  ),
                  testId: 'not-requested',
                },
              ]}
            />
          }
        />
      </div>
    </div>
  )
})

EvidenceTiles.displayName = 'EvidenceTiles'
